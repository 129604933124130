import React from 'react';

function App() {
  return (
    <div className="App">
            <img src="https://platformstorage001.blob.core.windows.net/prod/1717773672432-1000360181.webp" alt="My Image" />
      <img src="https://drishteemaster.blob.core.windows.net/bankingprod/1719470323025-gamsha.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-06-27T21:01:55Z&st=2024-06-27T13:01:55Z&spr=https&sig=NPyS3m86jPtMrQx4VwegczudwKQP4sxIxZfogUekxUw%3D" alt="My Image" />
    </div>
  );
}

export default App;